<template  lang="pug">
include ../../../configs/template.pug
div
  div.col-12.pb-0
    +select-validation('body.status_document', 'statusesList', 'status', 'nameLang', '["required"]')

  template(v-if="body.status_document === STATUSES_STATEMENT_CONSTANTS.NEW_ACCOUNT_SAILOR.ISC.APPROVED")
    v-radio-group(
      v-model="isExist"
      :label="`${$t('sailorExist')}:`"
      hide-details
      row).px-3.mt-0
      v-radio(
        v-for="item in existOptions"
        :key="item.label"
        :label="item.label"
        :value="item.value")
    div.col-12
      +select-validation('body.access_to_functional','listAccessToFunctionalISC','typeAccess','nameLang','["required"]')
      div(v-if="isExist").col-12
        +field-validation('sailorId', 'sailorId', '["required"]')(
          @keyup.enter="checkSailor"
          type="number")
    template(v-if="!isExist || (isExist && isSailorChecked && !isForeignPassport)")
      div.d-flex
        div.col-xs-12.col-sm-12.col-md-6.pb-0
          +field-validation('sailorInfo.last_name_eng', 'tableHeaders.last_name', '["required", "alphaEN"]')
        div.col-xs-12.col-sm-12.col-md-6.pb-0
          +field-validation('sailorInfo.first_name_eng', 'tableHeaders.first_name', '["required", "alphaEN"]')
      div.d-flex
        div.col-xs-12.col-sm-12.col-md-6.pb-0
          +date-picker-validation('sailorInfo.date_start', 'foreignPassportDateStart', 'sailorInfo.date_start', '["required"]')(max="2200-12-31")
        div.col-xs-12.col-sm-12.col-md-6.pb-0
          +date-picker-validation('sailorInfo.date_end', 'foreignPassportDateEnd', 'sailorInfo.date_end', '["required"]')(max="2200-12-31")
  div(v-if="body.status_document === STATUSES_STATEMENT_CONSTANTS.NEW_ACCOUNT_SAILOR.ISC.REJECT").col-12
    Reject
    //- VerificationRefusal(type="statementISC" ref="verificationRefusal")
  v-btn(
    v-if="isExist && !isSailorChecked"
    :loading="isLoader"
    @click="checkSailor"
    color="primary") {{ $t('check') }}
  v-btn(
    v-else-if="body.status_document !== STATUSES_STATEMENT_CONSTANTS.NEW_ACCOUNT_SAILOR.ISC.REJECT"
    :loading="isLoader"
    @click="setStatus"
    color="success") {{ $t('save') }}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { back, clearBody } from '@/mixins/main'
import { newStatementCreateAccountISC } from '@/mixins/validationRules'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
export default {
  name: 'StatementISCEdit',
  components: {
    Reject: () => import('./Reject.vue')
  },
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      STATUSES_STATEMENT_CONSTANTS,
      isSailorChecked: false,
      isForeignPassport: false,
      sailorId: null,
      existOptions: [
        {
          value: false,
          label: this.$t('no')
        },
        {
          value: true,
          label: this.$t('yes')
        }
      ],
      sailorInfo: {
        first_name_eng: null,
        last_name_eng: null,
        date_start: null,
        date_end: null
      },
      isExist: false,
      isLoader: false,
      back,
      body: {
        status_document: null,
        access_to_functional: null
      }
    }
  },
  computed: {
    ...mapState({
      lang: state => state.main.lang,
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      statuses: state => state.directory.statuses,
      listAccessToFunctionalISC: state => state.directory.listAccessToFunctionalISC.filter(el => el.is_active)
    }),
    statusesList () {
      return this.statuses.filter(item => [41, 36].includes(item.id))
    }
  },
  validations () { return newStatementCreateAccountISC(this) },
  watch: {
    sailorId () {
      this.isSailorChecked = false
      this.isForeignPassport = false
    }
  },
  methods: {
    ...mapActions(['checkForeignPassport', 'updateStatementISCById']),

    async checkSailor () {
      if (this.isLoader) return false

      if (this.$v.sailorId.$invalid) return this.$v.sailorId.$touch()

      this.isLoader = true

      const response = await this.checkForeignPassport(this.sailorId)
      if (response?.code === 200) {
        this.isForeignPassport = response.data.has_foreign_passport
        this.isSailorChecked = true
      } else if (response?.code === 404) this.$notification.error('sailorNotFound')

      this.isLoader = false
    },

    async updateStatus () {
      this.isLoader = true

      const body = { ...this.body }
      if (this.body.status_document === STATUSES_STATEMENT_CONSTANTS.NEW_ACCOUNT_SAILOR.ISC.APPROVED) {
        Object.assign(body, this.sailorInfo)
        if (this.isExist) body.sailor_id = this.sailorId
      }

      const response = await this.updateStatementISCById({ body: { ...clearBody(body) }, id: this.$route.params.id })
      if (response?.code === 200) {
        this.$router.push({ name: `passports-sailors`, params: { id: response.data.sailor_id } })
      }
      this.isLoader = false
    },

    setStatus () {
      if (this.$v.$invalid) return this.$v.$touch()

      this.$swal({
        title: this.$t('sureToUpdateStatus'),
        icon: 'warning',
        buttons: [this.$t('cancel'), this.$t('confirm')],
        dangerMode: true
      }).then(confirmation => confirmation && this.updateStatus())
    }
  }
}
</script>
